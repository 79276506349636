body,
h1,
h2,
h3,
h4,
h5,
p,
ol,
ul,
dl,
menu,
hr,
pre,
button,
input,
select,
textarea,
fieldset,
legend,
figure,
blockquote,
iframe,
dialog,
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

::selection {
  background: rgba(238, 255, 119, 0.99);
  color: #292929;
}

::-moz-selection {
  background: rgba(238, 255, 119, 0.99);
  color: #292929;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  -moz-text-size-adjust: 100%; /* Firefox Android */
  -webkit-text-size-adjust: 100%; /* iOS Safari 5+ */
  text-size-adjust: 100%;
  touch-action: manipulation;
  -moz-osx-font-smoothing: grayscale; /* Firefox Mac OS */
  -webkit-font-smoothing: antialiased; /* Chrome, Safari Mac OS */
  -webkit-tap-highlight-color: transparent;
  -ms-overflow-style: -ms-autohiding-scrollbar; /* Mimic Mac OS for Windows */
}

video,
img,
object,
svg,
iframe,
embed {
  display: block;
}

img,
svg,
object {
  font-size: 0;
}

img {
  inline-size: 100%;
  block-size: auto;
}

select,
button,
input,
[role='button'] {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-rendering: geometricPrecision;
}

p {
  -webkit-hyphens: none; /* Safari 5+ */
  hyphens: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.global-sr {
  display: block;
  position: absolute;
  inline-size: 1px;
  block-size: 1px;
  overflow: hidden;
}

/*
  Hubspot GDPR Modal custom styles
  Please excuse the !important. We want to be sure
  that we override any inline styles set by
  third party code.
*/
div#hs-eu-cookie-confirmation.hs-cookie-notification-position-bottom {
  width: 100% !important;
  right: 0 !important;
  left: unset !important;
  background-color: #2e1a69 !important;
}
div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner {
  background-color: #2e1a69 !important;
  color: #fff !important;
}
div#hs-eu-cookie-confirmation
  div#hs-eu-cookie-confirmation-inner
  div#hs-en-cookie-confirmation-buttons-area {
  margin-right: 0 !important;
  justify-content: center !important;
}

div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner p {
  margin: 0 0 12px !important;
  color: #fff !important;
}

div#hs-eu-cookie-confirmation-button-group a#hs-eu-confirmation-button {
  color: #2e1a69 !important;
}

@media only screen and (min-width: 768px) {
  div#hs-eu-cookie-confirmation.hs-cookie-notification-position-bottom {
    width: 33% !important;
  }
}
